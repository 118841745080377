import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import React, { createContext, useState, useEffect } from 'react';

export const ConfigContext = createContext({});

function ConfigContextProvider(props) {
  const [config, setConfig] = useState({});

  useEffect(() => {
    let input = props;

    if (_isEmpty(input?.csConfig) || _isEmpty(input?.appConfig)) {
      const sessionConfig = window.sessionStorage.getItem('config');
      if (sessionConfig) {
        input = JSON.parse(sessionConfig);
      }
    }

    const projectId = _get(input, 'csConfig.projectId', '');
    const isDev = projectId === 'fired-up-dev' || projectId === 'fired-up-demo';

    const out = {
      ...config,
      appConfig: input.appConfig,
      CS_CONFIG: input.csConfig,
      isDev,
    };

    window.sessionStorage.setItem('config', JSON.stringify(out));

    setConfig(out);
  }, [props]);

  return (
    <ConfigContext.Provider value={config}>
      {props.children}
    </ConfigContext.Provider>
  );
}

export default ConfigContextProvider;
